import { apiv1 } from "@/request/apiv1";
import qs from "qs";

let baseUrl = '/clients/clues'

export function GetMosaicMessageList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetMosaicMessageById(id = null) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/${id}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

