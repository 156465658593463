<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <!-- <template #tableControl></template> -->
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'name'">
              <span
                style="padding-left: 5px"
                title="点击查看详情"
                class="text-nav"
                @click="onShowDetail(scope.row)"
                >{{ scope.row.name }}</span
              >
            </span>

            <span v-else-if="column.prop === 'activated'">
              <el-tag type="success" v-if="scope.row.activated">已激活</el-tag>
              <el-tag type="danger" v-if="!scope.row.activated">未激活</el-tag>
            </span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>

            <span v-else-if="column.prop === 'entWxBound'">
              <el-tag type="success" v-if="scope.row.entWxBound">已绑定</el-tag>
              <el-tag type="danger" v-if="!scope.row.entWxBound">未绑定</el-tag>
            </span>

            <span v-else-if="column.prop === 'roles'">
              <span v-if="scope.row.roles.length <= 0">/</span>
              <el-popover v-else placement="left" width="300" trigger="click">
                <el-tag
                  class="tag"
                  v-for="item in scope.row.roles"
                  :key="item.id"
                  type="info"
                  style="margin-right: 10px; margin-bottom: 10px"
                >
                  <i class="el-icon-paperclip"></i>
                  {{ item.name }}
                </el-tag>
                <el-button slot="reference" type="text" icon="el-icon-view"
                  >{{ scope.row.roles[0].name }}（{{
                    scope.row.roles.length
                  }}个）</el-button
                >
              </el-popover>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <!-- <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.canUpdate"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >编辑</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template> -->
        </el-table-column>
      </template>
      <template #multipleControl> </template>
    </BasicTable>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import PageHeader from "@/components/PageHeader";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { checkPermission } from "@/utils/auth";
import { GetMosaicMessageList } from "./api";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  components: {
    BasicTable,
    PageHeader,
    FilterSection,
  },
  data() {
    return {
      tableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      orderProp: "createdAt",
      orderDirection: "descending",
      orderPropObj: {},
      tableFilters: {},
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "createdAt",
            label: "创建日期",
            minWidth: 200,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "状态",
            showColumn: false,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },

          {
            prop: "firstName",
            label: "firstName",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastName",
            label: "lastName",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "email",
            label: "邮箱",
            minWidth: 180,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "country",
            label: "国家",
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "zipCode",
            label: "邮编",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "phoneNo",
            label: "电话",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 180,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 100,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          // {
          //   prop: "controlColumn",
          //   fixed: "right",
          //   align: "center",
          //   showColumn: true,
          //   label: "操作",
          //   width: 130,
          // },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableData() {
      this.tableLoading = true;
      GetMosaicMessageList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        disabled: this.showDisable,
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
              isEdit: false,
            };
          });

          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError(err, "拉取列表错误");
        });
    },

    onRefresh() {
      this.getTableData();
    },
    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderDirection = order;
        this.orderProp = prop;
      }
      this.page = 0;
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped></style>
